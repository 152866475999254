<template>
  <div>
    <div class="unit-list-tab edit-unit-scroll">
      <!-- <b-tabs pills v-model="selectedTab">
        <b-tab active> -->
      <!-- <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t("unit.UnitDetails") }}</span>
          </template> -->
      <b-card class="mt-36">
        <div class="m-2-br-bottm-device-2 back-manage-mobile">
          <b-row>
            <b-col
              cols="6"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0 col-back-6"
              ><h4 class="card-title" v-if="!($route.name === 'view-unit')">
                {{ $t("unit.edit") }}
              </h4>
              <h4 class="card-title" v-if="$route.name === 'view-unit'">
                {{ $t("unit.preview") }}
              </h4>
            </b-col>
            <b-col
              cols="6"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <div class="text-right">
                <DeviceSensorPreviewModal
                  v-if="unit && unit.device_list && unit.device_list.length"
                  class="mr-1"
                  :devices="
                    unit &&
                    unit.device_list &&
                    unit.device_list.map((u) => u.id)
                  "
                  :unitName="unit && unit.name"
                  :unitId="unit && unit.id"
                ></DeviceSensorPreviewModal>
              </div>
              <div>
                <b-button
                  variant="outline-primary"
                  class="v2-back"
                  @click="redirectUnitList"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="card-divider"></div>

        <div class="mt-2">
          <b-form class="mt-2">
            <validation-observer ref="unitRules" tag="form">
              <UnitCreationStepOneData
                :changeInput="changeInput"
                :unit="unit"
                :isEnabled="isEnabled"
                :currentUnit="currentUnit"
                :resetForm="resetForm"
                :isSelectedTab="isSelectedTab"
                :refreshed="refreshed"
                :isUnitOnlyAllocated="is_unit_only_allocated"
                :isUnitOwner="isUnitOwner"
              />
            </validation-observer>
          </b-form>
        </div>
      </b-card>
      <!-- </b-tab> -->

      <!-- <b-tab>
          <template #title>
            <feather-icon icon="ClockIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{
              $t("device.tabHistory")
            }}</span>
          </template>
          <b-card class="mt-36">
            <h4 class="card-title history-title-mb">
              {{ $t("device.historytitle") }}
            </h4>
            <div class="card-divider"></div>
            <div class="mt-2">
              <b-row class="history-outer">
                <b-col md="4">
                  <b-form-group :label="$t('device.StartDate')">
                    <flat-pickr
                      v-model="filter_start_date"
                      class="form-control date-time-input"
                      :placeholder="$t('device.EnterStartDate')"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i'
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="$t('device.EndDate')">
                    <flat-pickr
                      v-model="filter_end_date"
                      class="form-control date-time-input"
                      :placeholder="$t('device.EnterEndDate')"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i'
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group>
                    <b-button
                      class="filter-button-top min-100"
                      variant="primary"
                      @click="unitAllocationHistoryCheck"
                      >{{ $t("device.search") }}</b-button
                    >
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group>
                    <b-button
                      class="filter-button-top min-100"
                      variant="outline-primary"
                      @click="clearStartEndDate"
                      >{{ $t("device.Clear") }}</b-button
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <History
              :isUnitActionButton="true"
              :isPaginationVisible="true"
              :myHistoryData="allocationHistory"
              :currentPage="currentPage"
              :perPage="perPage"
              :totalRows="totalUnits"
              :callMyApiPart="callMyApiPart"
              :selectedAllocatedToId="selectedAllocatedToId"
              :showPopUp="showPopUp"
            />
          </b-card>
        </b-tab> -->
      <!-- </b-tabs> -->
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { LMap, LTileLayer, LMarker, LCircle, LPolygon } from "vue2-leaflet";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import { GeoSearchControl } from "leaflet-geosearch";
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import formValidation from "@core/comp-functions/forms/form-validation";
import DeviceService from "@/libs/api/device-service";
import AccountService from "@/libs/api/account-service";
import constants from "@/utils/constants";
import flatPickr from "vue-flatpickr-component";
import * as moment from "moment";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BForm,
  BButton,
  BTab,
  BTabs,
  BCard
} from "bootstrap-vue";
import { required } from "@validations";
import UnitService from "@/libs/api/unit-service";
import image from "../../assets/images/pages/avatar.svg";
import UnitCreationStepOneData from "./UnitCreationStepOneData";
import History from "@/layouts/components/History.vue";
import DeviceSensorPreviewModal from "@/views/DeviceOnboarding/DeviceSensorPreviewModal.vue";
import store from "@/store";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    BCardCode,
    LCircle,
    LPolygon,
    UnitCreationStepOneData,
    BForm,
    BButton,
    BTab,
    BTabs,
    History,
    flatPickr,
    BCard,
    DeviceSensorPreviewModal,
    AllocationPopUp: () => import("../../views/Unit/AllocationPopUp.vue")
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      downloadUrl: process.env.VUE_APP_FILE_URL,
      unit: null,
      isEnabled: false,
      startIndex: 0,
      disabled: false,
      showLoading: false,
      initialUnit: null,
      unitReAllocateTo: [],
      attachedUnitTo: [],
      deviceListOption: [],
      unitDriver: [],
      statusOption: ["ACTIVE", "INACTIVE", "DAMAGED", "BLOCKED"],
      option: ["10", "20", "30"],
      unitIcon: null,
      img: image,
      required,
      refFormObserver,
      getValidationState,
      latLng: latLng(0, 0),
      zoom: 10,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159],
      initialUnit: null,
      downloadUrl: process.env.VUE_APP_FILE_URL,
      currentUnit: null,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1"
      },
      allocationHistory: [],
      currentPage: 1,
      totalUnit: 0,
      perPage: 10,
      selectedTab: null,
      filter_start_date: null,
      filter_end_date: null,
      totalUnits: null,
      oldUnit: null,
      selectedAllocatedToId: null,
      allocationData: null,
      c1: null,
      status: null,
      previousEndDate: null,
      previousStartDate: null,
      is_unit_only_allocated: false,
      isUnitOwner: false
    };
  },
  props: ["size"],
  mounted() {
    if (this.$route.name === "edit-unit" || this.$route.name === "view-unit") {
      if (this.$route.params.id) {
        this.isEnabled = this.$route.params.isEnabled == true;
        this.is_unit_only_allocated = this.$route.query.is_unit_only_allocated;
        this.isUnitOwner = this.$route.params.is_unit_owner;
        this.getOneUnit(this.$route.params.id);
      } else {
        this.$router.go(-1);
      }
    }
  },
  watch: {
    unitIcon(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(async (value) => {
              this.unit.unitIcon = value;
            })
            .catch((e) => {});
        }
      }
    }
  },
  methods: {
    refreshed(id) {
      if (id && id != this.$route.params.id) {
        this.$router.replace({ params: { id: id } }).catch(() => {});
        if (this.$route.params.id) {
          this.getOneUnit(this.$route.params.id);
        }
      }
    },
    redirectUnitList() {
      this.$router.push({ name: "unit-list" });
    },
    isSelectedTab() {
      this.selectedTab = 1;
      if (this.currentUnit && this.currentUnit.allocate_to_id) {
        this.unit = Object.assign(this.currentUnit, {});
        this.selectedAllocatedToId = this.currentUnit.allocate_to_id;
      }
    },
    callMyApiPart(data) {
      this.perPage = data.perPage || 10;
      this.currentPage = data.currentPage || 1;
      this.unitAllocationHistory();
    },
    async changeInput(unit) {
      this.unit = unit;
    },
    mapShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        try {
          this.$refs.mymap.mapObject.invalidateSize();
        } catch (e) {
          console.log("Inside catch", e);
        }
      }, 100);
      // setup
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latLng = [position.coords.latitude, position.coords.longitude];
          },
          (error) => {
            console.log(error);
          }
        );
      }
      const provider = new OpenStreetMapProvider();
      const map = this.$refs.mymap.mapObject;
      const searchControl = new GeoSearchControl({
        provider,
        autoComplete: true,
        autoCompleteDelay: 250,
        showMarker: false
      });

      map.addControl(searchControl);
      map.on("geosearch/showlocation", this.geosearch);
    },
    geosearch(e) {
      this.latLng = [e.marker._latlng.lat, e.marker._latlng.lng];
    },
    showlocation(e) {
      this.latLng = [e.latlng.lat, e.latlng.lng];
    },
    updatelocation(latLng) {
      this.latLng = [latLng.lat, latLng.lng];
    },
    async getAlldeviceList() {
      try {
        let response = await new DeviceService().deviceList({});
        if (response && response.data) {
          this.deviceListOption = response.data.devices;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      this.mapShown();
      return true;
    },

    async getChildAccounts() {
      try {
        let response = await new AccountService().getChildAccounts({});

        if (response && response.data) {
          this.unitReAllocateTo = this.getAllDealersData(response.data);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    getAllDealersData(data) {
      const result = [];
      function recursive(data) {
        result.push({ id: data.id, name: data.name });
        if (data.children.length == 0) {
          return;
        }
        for (let i = 0; i < data.children.length; i++) {
          recursive(data.children[i]);
        }
      }
      recursive(data[0]);
      return result;
    },
    async getAllUsers() {
      try {
        let response = await new AccountService().getAccountUsers({});
        if (response && response.data) {
          this.unitDriver = response.data.users.map((u) => {
            return {
              id: u.id,
              title: u.first_name + " " + u.last_name,
              value: u.id
            };
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getOneUnit(id) {
      this.showLoading = true;
      let response = await new UnitService().getUnitDataV2({
        unit_id: id,
        account_id: localStorage.getItem("USER_ACCOUNT_ID")
      });

      if (response && response.data) {
        const data = response.data;
        const routerName = this.$route.name;
        const boo = this.$route.query.is_unit_only_allocated;
        const boo1 = this.$route.query.isUnitOwner;
        const edita =
          typeof boo == "boolean" ? boo : boo == "false" ? false : true;
        const kdjjf =
          typeof boo1 == "boolean" ? boo1 : boo1 == "false" ? false : true;
        if (
          routerName == "edit-unit" &&
          (!data.is_unit_editable ||
            (data.is_unit_editable &&
              !this.checkAbility({
                action: constants.PERMISSIONS_ACTION.UPDATE,
                subject: constants.PERMISSIONS_MODEL.UNITS
              })))
        ) {
          store.commit("authorized/SET_AUTH_PERMISSION", false);
          store.commit("authorized/SET_LAST_ROUTE", "unauthorized");
        }
        const {
          name,
          devices,
          allotted_account,
          owner_account,
          operator,
          position,
          file_name,
          icon
        } = data;

        const unitObj = {
          ...data,
          name,
          device_list: devices,
          allocate_to_id:
            (allotted_account && allotted_account.id) ||
            (owner_account && owner_account.id) ||
            "",
          status: data.status,
          labels: data.labels,
          attached_unit_to_id: data.attached_unit_to_id || data.pid,
          unit_driver_id: (operator && operator.id) || null,
          latLng: position ? [position.latitude, position.longitude] : [0, 0],
          latitude: (position && position.latitude) || 0,
          longitude: (position && position.longitude) || 0,
          file_name,
          file_name_url: icon,
          start_date: allotted_account
            ? allotted_account.start_date
            : moment().format(),
          end_date: allotted_account ? allotted_account.end_date : null,
          is_unit_editable: data.is_unit_editable
        };
        // this.$route.params.is_unit_editable = data.is_unit_editable;
        this.currentUnit = Object.assign({}, unitObj);

        this.currentUnit.file_name = this.currentUnit.file_name;
        this.currentUnit.file_name_url = this.currentUnit.icon;
        this.currentUnit.unit_icon = this.currentUnit.icon;
        this.showLoading = false;
        this.currentUnit.device_list = this.currentUnit.device_list.map(
          (d) => d.id
        );
        this.unit = JSON.parse(JSON.stringify(unitObj));

        this.allocationData = JSON.parse(JSON.stringify(unitObj));
        this.oldUnit = JSON.parse(JSON.stringify(unitObj));
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.$router.push({ name: "unit-list" });
      }
    },
    async onUnitChange(prevIndex, nextIndex) {
      this.startIndex = nextIndex;
    },
    resetForm() {
      this.unit = JSON.parse(JSON.stringify(this.oldUnit));
    },
    clearStartEndDate() {
      if (this.filter_end_date || this.filter_start_date) {
        this.filter_end_date = this.filter_start_date = null;
        this.unitAllocationHistory();
      }
    },
    unitAllocationHistoryCheck() {
      if (
        this.filter_start_date == this.previousStartDate &&
        this.filter_end_date == this.previousEndDate
      ) {
        return;
      } else {
        this.unitAllocationHistory();
      }
    },
    async unitAllocationHistory() {
      if (this.$route.params.id) {
        this.showLoading = true;
        let response = await new UnitService().unitAllocationHistory({
          id: this.$route.params.id,
          start_date: this.filter_start_date
            ? this.convertDatePerTimezone(this.filter_start_date)
            : null,
          end_date: this.filter_end_date
            ? this.convertDatePerTimezone(this.filter_end_date)
            : null,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });
        this.previousStartDate = this.filter_start_date;
        this.previousEndDate = this.filter_end_date;
        this.showLoading = false;
        if (response && response.data) {
          this.show = false;
          let allocateHistory = [];
          if (
            response.data.unitAllocationHistory &&
            response.data.unitAllocationHistory.length
          ) {
            response.data.unitAllocationHistory.map((h) => {
              let history = {
                account: h.account,
                start_date: h.start_date,
                end_date: h.end_date ? h.end_date : "",
                unit_id: h.unit_id,
                isEditable: h.isEditable ? h.isEditable : "",
                isDeleteable: h.isDeleteable ? h.isDeleteable : ""
              };
              allocateHistory.push(history);
              this.totalUnits = response.data.unitAllocationHistory.count || 0;
            });
          }

          this.allocationHistory = allocateHistory;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    async showPopUp(id) {
      await this.getOneUnit(id);
      this.c1 = "src/views/Unit/AllocationPopUp.vue";
      this.status = true;
      this.allocationData = this.allocationHistory.find(
        (a) => a.allocate_to_id == id
      );
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
.map-outer {
  width: 100%;
}
.unit-list-tab {
  .unit-icon-modal {
    .unit-icon-card {
      height: 220px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.edit-unit-scroll {
  .unit-create-scroll {
    height: calc(100vh - 300px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
